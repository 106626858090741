import * as Sentry from "@sentry/browser";
import moment from "moment";

const getJsonFeatures = () => {
  return JSON.stringify({
    "Object.values": Object.values! ? "yes" : "no",
    "Object.entries": Object.entries! ? "yes" : "no",
    "Array.prototype.fill": Array.prototype.fill! ? "yes" : "no",
    "Array.prototype.find": Array.prototype.find! ? "yes" : "no",
    "Array.prototype.findIndex": Array.prototype.findIndex! ? "yes" : "no",
    "Array.prototype.includes": Array.prototype.includes! ? "yes" : "no",
    "String.prototype.startsWith": String.prototype.startsWith! ? "yes" : "no",
    "Array.from": Array.from! ? "yes" : "no",
    "Number.isNaN": Number.isNaN! ? "yes" : "no",
  });
};

const browserSupportsAllFeatures = () => {
  return (
    Object.values! &&
    Object.entries! &&
    Array.prototype.fill! &&
    Array.prototype.find! &&
    Array.prototype.findIndex! &&
    Array.prototype.includes! &&
    String.prototype.startsWith! &&
    Array.from! &&
    Number.isNaN!
  );
};

const fixMomentJson = () => {
  moment.fn.toJSON = function () {
    return this.toISOString(true);
  };
};

const polyfill = () => {
  fixMomentJson();
  const jsonFeatures = getJsonFeatures();
  if (browserSupportsAllFeatures()) {
    return Promise.resolve();
  } else {
    return import(/* webpackChunkName: 'polyfill' */ "./polyfill")
      .then(() => {
        console.log("Polyfills loaded");
        console.log(jsonFeatures);
        console.log(getJsonFeatures());
        Sentry.captureMessage("Polyfills loaded");
        Sentry.captureMessage(jsonFeatures);
        Sentry.captureMessage(getJsonFeatures());
      })
      .catch((error) => {
        console.log("Unable to load polyfills");
        console.error(error);
        // Handle failure
      });
  }
};

export default polyfill;
