import {
  IOverdraftQuestion,
  IOverdraftsSectionFormData,
} from "../../OverdraftSection/OverdraftsDrawer/OverdraftsSingleForm";
import {
  conoscenzaEsperienzaCriteriaType,
  ICap,
  obiettiviInvestimentoType,
  orizzonteTemporaleType,
  propensioneRischioType,
  situazioneFinanziariaType,
} from "../../../types";
import {MultiKeys, SingleKeys} from "./IddForm";

export const conoscenzaEsperienzaCriteria: conoscenzaEsperienzaCriteriaType[] =
  ["Bassa", "Medio-bassa", "Medio-alta", "Alta"];

export const situazioneFinanziariaCriteria: situazioneFinanziariaType[] = [
  "Critica",
  "Debole",
  "Moderata",
  "Solida",
];

export const obiettiviInvestimentoCriteria: obiettiviInvestimentoType[] = [
  "Conservazione",
  "Reddito",
  "Crescita",
  "Crescita rilevante",
];

export const propensioneRischioCriteria: propensioneRischioType[] = [
  "Cauto",
  "Prudente",
  "Bilanciato",
  "Dinamico",
];

export const orizzonteTemporaleCriteria: orizzonteTemporaleType[] = [
  "Molto breve",
  "Breve",
  "Medio",
  "Lungo",
];

interface IDomandeIddQuestion {
  answers: Array<{text: string; value: string}>;
  label: string;
  multiple?: boolean;
  name: MultiKeys | SingleKeys;
  getInitialValue?: (cap: ICap) => string | Array<null | true>;
}

interface IDomandeIddSection {
  questions: IDomandeIddQuestion[];
  title: string;
}

export interface IDomandeIdd {
  sections: IDomandeIddSection[];
}

const domandeIdd: IDomandeIdd = {
  sections: [
    {
      questions: [
        {
          answers: [
            {text: "Nessun titolo di studio", value: "1"},
            {text: "Licenza scuola primaria/media", value: "2"},
            {text: "Diploma di scuola superiore", value: "3"},
            {text: "Laurea", value: "4"},
            {
              text: "Laurea o specializzazione post-universitaria in campo economico finanziario",
              value: "5",
            },
          ],
          label: "Quale titolo di studio ha conseguito?",
          name: "question_b1",
        },
      ],
      title: "Dati anagrafici",
    },
    {
      questions: [
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "Quando si acquistano titoli emessi da uno Stato potrebbe verificarsi l'ipotesi che non si abbia il rimborso del capitale?",
          name: "question_c1",
        },
        {
          answers: [
            {
              text: "La società più rischiosa emette obbligazioni con un rendimento più basso",
              value: "1",
            },
            {
              text: "La società più rischiosa emette obbligazioni con un rendimento più alto",
              value: "2",
            },
            {
              text: "La società più famosa emette obbligazioni con un rendimento più alto",
              value: "3",
            },
            {
              text: "I rendimenti dipendono dal tasso di cambio dell'euro",
              value: "4",
            },
            {text: "Non so", value: "5"},
          ],
          label:
            "Tre società con le stesse caratteristiche emettono obbligazioni a tasso fisso in euro con la stessa durata. I titoli emessi dalla prima rendono il 2% all'anno, quelli emessi dalla seconda il 3,5%, quelli emessi dalla terza il 6%. Perché i rendimenti sono diversi?",
          name: "question_c2",
        },
        {
          answers: [
            {text: "Nessun rischio", value: "1"},
            {text: "Rischio di cambio e rischio di mercato", value: "2"},
            {text: "Rischio di cambio ", value: "3"},
            {text: "Rischio di interesse ", value: "4"},
            {text: "Non so", value: "5"},
          ],
          label:
            "Investendo in un titolo azionario quotato sul mercato statunitense a quale dei seguenti rischi ci si espone?",
          name: "question_c3",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
          ],
          label:
            "Lavora o ha mai lavorato in ambito finanziario (in azienda e/o come privato)?",
          name: "question_c4",
        },
        {
          answers: [
            {text: "Almeno mensile", value: "1"},
            {text: "Almeno trimestrale", value: "2"},
            {text: "Almeno annualmente", value: "3"},
            {text: "Mai/raramente", value: "4"},
          ],
          label: "Con quale frequenza opera in prodotti finanziari?",
          name: "question_c5",
        },
        {
          answers: [
            // {text: "Nessuna", value: "1"},
            {text: "Obbligazioni/Titoli di stato", value: "2"},
            {text: "Azioni", value: "3"},
            {text: "Fondi di investimento strutturati", value: "4"},
            {text: "Prodotti di investimento assicurativi", value: "5"},
            {text: "Derivati", value: "6"},
            {text: "Altri prodotti complessi", value: "7"},
          ],
          getInitialValue: (cap) => {
            const values = cap.data.overdrafts!
              .risparmioFuturo as IOverdraftsSectionFormData;

            return [
              null,
              null,
              (values.obbligazioni as IOverdraftQuestion).checked ? true : null,
              (values.azioni as IOverdraftQuestion).checked ? true : null,
              (values.fondi as IOverdraftQuestion).checked ? true : null,
              (values["prodotti-assicurativi"] as IOverdraftQuestion).checked
                ? true
                : null,
              (values.derivati as IOverdraftQuestion).checked ? true : null,
              (values.altro as IOverdraftQuestion).checked ? true : null,
            ];
          },
          label:
            "In che tipologia di prodotti finanziari investe/ha investito? (Risposta multipla)",
          multiple: true,
          name: "question_c6",
        },
      ],
      title: "Esperienza finanziaria e conoscenze possedute",
    },
    {
      questions: [
        {
          answers: [
            {text: "Inferiore o uguale a 15.000 euro", value: "1"},
            {
              text: "Superiore a 15.000 euro e inferiore o uguale a 30.000 euro",
              value: "2",
            },
            {
              text: "Superiore a 30.000 euro e inferiore o uguale a 45.000 euro",
              value: "3",
            },
            {
              text: "Superiore a 45.000 euro e inferiore o uguale a 70.000 euro",
              value: "4",
            },
            {text: "Superiore a 70.000 euro", value: "5"},
          ],
          getInitialValue: (cap) => {
            const netYearIncome =
              cap.data.employments!.currentEmployment.redditoNormalizzato * 12 +
              cap.data.incomes!.incomes.reduce((total, income) => {
                return total + (parseFloat(income.value) * 12 || 0);
              }, 0);
            if (netYearIncome < 15000) {
              return "1";
            } else if (netYearIncome < 30000) {
              return "2";
            } else if (netYearIncome < 45000) {
              return "3";
            } else if (netYearIncome < 70000) {
              return "4";
            } else {
              return "5";
            }
          },
          label: "Qual è la sua capacità reddituale annua netta?",
          name: "question_d1",
        },
        {
          answers: [
            {text: "Meno del 5%", value: "1"},
            {text: "Tra il 5% e il 15%", value: "2"},
            {text: "Oltre il 15%", value: "3"},
          ],
          getInitialValue: (cap) => {
            const netMonthIncome =
              cap.data.employments!.currentEmployment.redditoNormalizzato +
              cap.data.incomes!.incomes.reduce(
                (total, income) => total + (parseFloat(income.value) || 0),
                0
              );
            // Evitiamo la divisione per 0
            if (netMonthIncome === 0) {
              return "1";
            }
            const monthSaving = parseFloat(cap.data.saving!.saving) || 0;
            const percentSaving = (monthSaving / netMonthIncome) * 100;

            if (percentSaving < 5) {
              return "1";
            } else if (percentSaving < 15) {
              return "2";
            } else {
              return "3";
            }
          },
          label:
            "In termini percentuali, quanto riesce a risparmiare del suo reddito annuo?",
          name: "question_d2",
        },
        {
          answers: [
            {text: "Inferiore o uguale a 50.000 euro", value: "1"},
            {
              text: "Superiore a 50.000 euro e inferiore o uguale a 100.000 euro",
              value: "2",
            },
            {
              text: "Superiore a 100.000 euro e inferiore o uguale a 300.000 euro",
              value: "3",
            },
            {
              text: "Superiore a 300.000 euro e inferiore o uguale a 500.000 euro",
              value: "4",
            },
            {text: "Superiore a 500.000 euro", value: "5"},
          ],
          label:
            "Quale è la sua consistenza patrimoniale in termini di prodotti finanziari, immobili e liquidità?",
          name: "question_d3",
        },
        {
          answers: [
            {text: "Inferiori o uguali a 15.000 euro", value: "1"},
            {
              text: "Superiori a 15.000 euro e inferiori o uguali a 30.000 euro",
              value: "2",
            },
            {
              text: "Superiori a 30.000 euro e inferiori o uguali a 60.000 euro",
              value: "3",
            },
            {text: "Superiori a 60.000 euro", value: "4"},
          ],
          label:
            "A quanto ammontano i suoi impegni finanziari ancora da rimborsare?",
          name: "question_d4",
        },
        {
          answers: [
            {text: "Occupato con contratto a tempo indeterminato", value: "1"},
            {text: "Lavoratore autonomo/libero professionista", value: "2"},
            {text: "Pensionato", value: "3"},
            {
              text: "Occupato con contratto temporaneo, di collaborazione coordinata e continuativa, a progetto, interinale, ecc.",
              value: "4",
            },
            {text: "Non occupato", value: "5"},
          ],
          getInitialValue: (cap) => {
            if (
              (cap.data.employments!.currentEmployment.tipologia ===
                "dipendente" ||
                cap.data.employments!.currentEmployment.tipologia ===
                  "subordinato") &&
              !cap.data.employments!.currentEmployment.scadenza
            ) {
              return "1";
            } else if (
              cap.data.employments!.currentEmployment.tipologia === "autonomo"
            ) {
              return "2";
            } else if (
              cap.data.employments!.currentEmployment.tipologia === "pensione"
            ) {
              return "3";
            } else if (
              (cap.data.employments!.currentEmployment.tipologia ===
                "dipendente" ||
                cap.data.employments!.currentEmployment.tipologia ===
                  "subordinato") &&
              !!cap.data.employments!.currentEmployment.scadenza
            ) {
              return "4";
            } else {
              return "5";
            }
          },
          label: "Quale è attualmente il suo stato occupazionale?",
          name: "question_d5",
        },
      ],
      title: "Situazione finanziaria e capacità di sostenere le perdite",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Conservazione del patrimonio e del potere d'acquisto",
              value: "1",
            },
            {
              text: "Crescita del capitale, pur accettando il rischio di oscillazioni di valore",
              value: "2",
            },
            {
              text: "Crescita significativa del capitale, sopportando anche forti oscillazioni di valore",
              value: "3",
            },
          ],
          label: "Quali sono gli obiettivi del suo investimento?",
          name: "question_e1",
        },
        {
          answers: [
            {text: "Molto-breve (inferiore o uguale a 1 anno)", value: "1"},
            {text: "Breve (superiore a 1 anno e fino a 3 anni)", value: "2"},
            {text: "Medio (superiore a 3 anni e fino a 5 anni)", value: "3"},
            {text: "Lungo (superiore a 5 anni)", value: "4"},
          ],
          label:
            "Qual è il periodo di tempo per il quale desidera conservare l'investimento?",
          name: "question_e2",
        },
        {
          answers: [
            {
              text: "Disinvesto immediatamente perché non sono disposto ad accettare ulteriori perdite",
              value: "1",
            },
            {
              text: "Mantengo l'investimento in attesa che recuperi il valore",
              value: "2",
            },
            {
              text: "Attendo un aumento di valore rispetto all' investimento",
              value: "3",
            },
            {
              text: "Aumento il mio investimento per acquistare quotazioni più favorevoli",
              value: "4",
            },
          ],
          label: "Qual è la sua reazione a movimenti negativi di mercato?",
          name: "question_e3",
        },
        {
          answers: [
            {text: "Fino al 5%", value: "1"},
            {text: "Fino al 10%", value: "2"},
            {text: "Fino al 15%", value: "3"},
            {text: "Anche superiore al 15%", value: "4"},
          ],
          label:
            "Ipotizzando un andamento negativo dei mercati finanziari, qual è la perdita massima che sarebbe disposto a sopportare senza sentire la necessità di liquidare tutto o in parte i suoi investimenti?",
          name: "question_e4",
        },
      ],
      title: "Obiettivi di investimento e propensione al rischio",
    },
  ],
};

export default domandeIdd;
