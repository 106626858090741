import {createAsyncThunk} from "@reduxjs/toolkit";
import last from "lodash/last";
import {actions as capsActions} from "../../../Caps/actions";
import {createAsyncThunkAndReset} from "../../../helpers/createAsyncThunkAndReset";
import errors, {ErrorCodes} from "../../../helpers/errors";
import {actions as usersActions} from "../../../Users/actions";
import service from "../../service";
import {
  beneProposalEntityUtility,
  IBeneProposalEntity,
  IBeneProposalParams,
  IDataSnapshot,
} from "../../types";
import {actions as entityActions} from "../entities";

export const create = createAsyncThunkAndReset(
  "beneProposals/create",
  async (
    beneProposal: Partial<IBeneProposalEntity> & {
      dateEffect: string;
    },
    thunkAPI
  ) => {
    const createdBeneProposal = await service.create(beneProposal);
    const {
      result,
      entities: {users, beneProposals, caps},
    } = beneProposalEntityUtility.normalizeEntity(createdBeneProposal);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneProposals ?? {}));

    return {beneProposalId: result};
  }
);

// const data = JSON.parse(createdBeneProposal.jsonData) as IDataSnapshot;
// const lastData = last(data);
// const uid = lastData?.outputAccessoProdotto?.uid;
//
// if (!uid) {
//   // Non abbiamo lo uid
//   return thunkAPI.rejectWithValue({
//     beneProposalsId: result,
//     error: errors[ErrorCodes.BENE_UID_NOT_VALID],
//   });
// } else if (lastData?.outputAccessoProdotto?.outcome?.messages !== null) {
//   // C'è lo uid ma abbiamo cmq degli errori conosciuti
//   return thunkAPI.rejectWithValue({
//     beneProposalsId: result,
//     error: {
//       ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
//       message:
//         `Bene Assicurazioni ha tornato questo errore: "${
//           lastData?.outputAccessoProdotto?.outcome?.messages![0]
//         }". ` + errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
//     },
//   });
// }

//   const sentEmailBeneProposal = await service.sendEmail(result);
//   const {
//     entities: {
//       users: sentEmailUsers,
//       beneProposals: sentEmailBeneProposals,
//       caps: sentEmailCaps,
//     },
//   } = beneProposalEntityUtility.normalizeEntity(sentEmailBeneProposal);
//
//   thunkAPI.dispatch(usersActions.mergeItems(sentEmailUsers));
//   thunkAPI.dispatch(capsActions.mergeItems(sentEmailCaps));
//   thunkAPI.dispatch(entityActions.received(sentEmailBeneProposals ?? {}));
//
//   return {
//     beneProposalsId: result,
//     uid,
//   };
// });

export const get = createAsyncThunk(
  "beneProposals/get",
  async (id: string, thunkAPI) => {
    const beneProposal = await service.read(id);
    const {
      result,
      entities: {users, beneProposals, caps},
    } = beneProposalEntityUtility.normalizeEntity(beneProposal);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneProposals ?? {}));

    return {beneProposalsId: result};
  }
);

export const list = createAsyncThunk(
  "beneProposals/list",
  async (params: IBeneProposalParams, thunkAPI) => {
    const {numBeneProposals, beneProposalsList} = await service.list(params);
    const {
      result,
      entities: {users, beneProposals, caps},
    } = beneProposalEntityUtility.normalizeEntity(beneProposalsList);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneProposals ?? {}));

    return {beneProposalsIds: result, numBeneProposals, params};
  }
);

export const send = createAsyncThunkAndReset(
  "beneProposals/resend",
  async (
    {id}: {id: string},
    // dateEffect,
    // jsonProduct,
    // {id: string}, //  dateEffect: string; jsonProduct: string},
    thunkAPI
  ) => {
    const sentBeneProposal = await service.send(id); // , {
    // dateEffect,
    // jsonProduct,
    // });
    const {
      result,
      entities: {users, beneProposals, caps},
    } = beneProposalEntityUtility.normalizeEntity(sentBeneProposal);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneProposals ?? {}));

    const data = sentBeneProposal.jsonData
      ? (JSON.parse(sentBeneProposal.jsonData) as IDataSnapshot)
      : undefined;
    const lastData = last(data);
    const uid = lastData?.outputAccessoProdotto?.uid;

    if (!uid) {
      // Non abbiamo lo uid
      return thunkAPI.rejectWithValue({
        beneProposalsId: result,
        error: errors[ErrorCodes.BENE_UID_NOT_VALID],
      });
    } else if (lastData?.outputAccessoProdotto?.outcome?.messages !== null) {
      // C'è lo uid ma abbiamo cmq degli errori conosciuti
      return thunkAPI.rejectWithValue({
        beneProposalsId: result,
        error: {
          ...errors[ErrorCodes.BENE_NON_BLOCKING_ERROR],
          message:
            `Bene Assicurazioni ha tornato questo errore: "${lastData?.outputAccessoProdotto?.outcome?.messages[0]}". ` +
            errors[ErrorCodes.BENE_NON_BLOCKING_ERROR].message,
        },
      });
    }

    return {
      beneProposalsId: result,
      uid,
    };
  }
);

export const sendEmail = createAsyncThunk(
  "beneProposals/sendEmail",
  async (elementaryClassId: string, thunkAPI) => {
    const beneProposal = await service.sendEmail(elementaryClassId);
    const {
      entities: {users, beneProposals, caps},
    } = beneProposalEntityUtility.normalizeEntity(beneProposal);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(beneProposals ?? {}));
  }
);
